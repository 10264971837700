import React from "react";

/**
 * Load Mutiny scripts after first render using a reference to the `<head>` tag.
 *
 * The Mutiny docs mention that we should be loading these scripts by putting them
 * directly in the `<head>` tag, so that they start executing as soon as possible.
 *
 * Unfortunately, the Mutiny script messes with the root `<html>` tag, resulting
 * in a hydration mis-match error. We can suppress it, but Mutiny still often
 * triggers an error that it's re-declaring some `const` variables multiple times.
 *
 * The goal of doing it this way is to not mess with the DOM before React has had
 * a chance to hydrate and take over management of it, and to ensure we're not loading
 * it multiple times somehow to defend against the re-declaration error.
 */
export const useMutiny = () => {
  const headTagRef = React.useRef<HTMLHeadElement>(null);
  const [mutinyInjected, setMutinyInjected] = React.useState(false);

  React.useEffect(() => {
    if (mutinyInjected || !headTagRef.current) {
      return;
    }

    const headTag: HTMLHeadElement = headTagRef.current;

    /**
     * Inline script
     */
    const mutinyInlineScript = document.createElement("script");

    mutinyInlineScript.setAttribute("type", "text/javascript");
    mutinyInlineScript.innerHTML = mutinyInlineScriptSnippet();

    /**
     * Async script
     */
    const mutinyAsyncScript = document.createElement("script");

    mutinyAsyncScript.setAttribute("type", "text/javascript");
    mutinyAsyncScript.setAttribute(
      "src",
      "https://client-registry.mutinycdn.com/personalize/client/08917fcb42154b9a.js",
    );

    setMutinyInjected(true);

    headTag.append(mutinyInlineScript, mutinyAsyncScript);
  }, [mutinyInjected]);

  return {
    headTagRef,
  };
};

function mutinyInlineScriptSnippet() {
  return `(function(){var a=window.mutiny=window.mutiny||{};if(!window.mutiny.client){a.client={_queue:{}};var b=["identify","trackConversion"];var c=[].concat(b,["defaultOptOut","optOut","optIn"]);var d=function factory(c){return function(){for(var d=arguments.length,e=new Array(d),f=0;f<d;f++){e[f]=arguments[f]}a.client._queue[c]=a.client._queue[c]||[];if(b.includes(c)){return new Promise(function(b,d){a.client._queue[c].push({args:e,resolve:b,reject:d})})}else{a.client._queue[c].push({args:e})}}};c.forEach(function(b){a.client[b]=d(b)})}})();`;
}
