import * as React from "react";

export type SearchContextProps = {
  appId?: string;
  searchKey?: string;
  index?: string;
  // Ref used by the header and marketplace search bar input
  //
  // We hide the search bar in the header when on /marketplace because the
  // page itself renders a search bar. Once the user scrolls this search bar
  // past the top of the viewport we unhide the header search bar in the header.
  //
  // Since the <Header> component is rendered from the root of the render tree
  // and marketplace is not a child component, we put the ref in a context to
  // be accessed by the marketplace page
  searchInputRef: React.MutableRefObject<HTMLDivElement | null> | null;
};

export const defaultSearchContextProps: SearchContextProps = {
  appId: "not-set",
  searchKey: "not-set",
  index: "not-set",
  searchInputRef: null,
};

export const SearchContext = React.createContext<SearchContextProps>(
  defaultSearchContextProps,
);
