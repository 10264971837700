import * as Radix from "@radix-ui/themes";
import {
  LinksFunction,
  LoaderFunctionArgs,
  SerializeFrom,
  json,
} from "@remix-run/node";
import * as RemixReact from "@remix-run/react";
import * as SentryRemix from "@sentry/remix";
import { Vivid } from "@vendrinc/frontend-ts";
import theme from "@vendrinc/frontend-ts/theme.css?url";
import {
  useReducedMotion,
  MotionConfig,
  MotionGlobalConfig,
} from "framer-motion";
import * as React from "react";
import "@radix-ui/themes/styles.css";
import {
  AnalyticsContext,
  analytics as cachedAnalytics,
} from "./contexts/analyticsContext";
import { DialogContext } from "./contexts/dialogContext";
import { useMutiny } from "./hooks/useMutiny";
import styles from "./root.module.css";
import { SearchContext } from "~/contexts/searchContext";
import {
  newAnonymousId,
  readAnonymousIdCookie,
  setAnonymousIdCookie,
} from "~/domain/analytics/anonymousId";
import { isStaging } from "~/domain/constants";
import { useIsLoadingPage } from "~/hooks/useIsLoadingPage";
import { getConfig } from "~/lib/config.server";
import "./globals.css";

export const links: LinksFunction = () => {
  return [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },

    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
    {
      rel: "manifest",
      href: "/site.webmanifest",
    },
    { rel: "stylesheet", href: theme },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const { config } = await Promise.props({
    config: getConfig,
  });

  const responseHeaders = new Headers();

  const anonymousId = readAnonymousIdCookie(
    request.headers.get("Cookie") ?? "",
  );

  if (!anonymousId) {
    responseHeaders.append(
      "Set-Cookie",
      setAnonymousIdCookie(newAnonymousId()),
    );
  }

  const activeSpan = SentryRemix.getActiveSpan();

  return json(
    {
      anonymousId,
      sentryTrace: activeSpan
        ? SentryRemix.spanToTraceHeader(activeSpan)
        : null,
      sentryBaggage: activeSpan
        ? SentryRemix.spanToBaggageHeader(activeSpan)
        : null,
      CONFIG: {
        ALGOLIA_APP_ID: config.ALGOLIA_APP_ID,
        ALGOLIA_DEFAULT_INDEX: config.ALGOLIA_DEFAULT_INDEX,
        ALGOLIA_SEARCH_KEY: config.ALGOLIA_SEARCH_KEY,
        IRONZION_APP_URL: config.IRONZION_APP_URL,
        SEGMENT_WRITE_KEY: config.SEGMENT_WRITE_KEY,
        SENTRY_DSN: config.SENTRY_DSN,
        SENTRY_ENV: config.SENTRY_ENV,
        SENTRY_SAMPLE_RATE: config.SENTRY_SAMPLE_RATE,
        SENTRY_MASK_REPLAY_CONTENT: process.env.ENV === "prod",
      },
    },
    {
      headers: responseHeaders,
    },
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  // Use useRouteLoaderData instead of useLoaderData because the data
  // from the loader might not be available.
  const data = RemixReact.useRouteLoaderData<typeof loader>("root");

  const dialogContainer = React.useRef<null | HTMLDivElement>(null);
  const dialogContent = React.useRef<null | HTMLDivElement>(null);

  // Init Segment analytics & track every page view
  const location = RemixReact.useLocation();

  const segmentWriteKey = data?.CONFIG?.SEGMENT_WRITE_KEY;

  const initAnalytics = React.useCallback(() => {
    SentryRemix.addBreadcrumb({
      message: "Load Analytics",
    });

    return segmentWriteKey
      ? cachedAnalytics.load({
          writeKey: segmentWriteKey,
        })
      : cachedAnalytics;
  }, [segmentWriteKey]);

  React.useEffect(() => {
    if (isStaging) {
      console.log("STAGING");
    }
  }, []);

  // Search
  const searchInputRef = React.useRef<HTMLDivElement | null>(null);

  // Page transition loading state
  const { isLoading } = useIsLoadingPage();

  const analyticsContextProviderValue = React.useMemo(
    () => ({
      anonymousId: data?.anonymousId,
      analytics: cachedAnalytics,
    }),
    [data?.anonymousId],
  );

  const searchContextProviderValue = React.useMemo(
    () => ({
      appId: data?.CONFIG.ALGOLIA_APP_ID ?? "",
      index: data?.CONFIG.ALGOLIA_DEFAULT_INDEX ?? "",
      searchKey: data?.CONFIG.ALGOLIA_SEARCH_KEY ?? "",
      searchInputRef: searchInputRef,
    }),
    [
      data?.CONFIG.ALGOLIA_APP_ID,
      data?.CONFIG.ALGOLIA_DEFAULT_INDEX,
      data?.CONFIG.ALGOLIA_SEARCH_KEY,
    ],
  );

  const dialogProviderValue = React.useMemo(
    () => ({
      container: dialogContainer,
      content: dialogContent,
    }),
    [],
  );

  const sentryMetaTags = (() => {
    if (data?.sentryBaggage && data.sentryTrace) {
      return (
        <>
          <meta name="sentry-trace" content={data.sentryTrace} />
          <meta name="baggage" content={data.sentryBaggage} />
        </>
      );
    }

    return null;
  })();

  const { headTagRef } = useMutiny();

  const shouldReduceMotion = useReducedMotion();
  if (shouldReduceMotion) {
    MotionGlobalConfig.skipAnimations = true;
  }

  React.useEffect(() => {
    // Remix docs recommends it to make sure the code runs in the browser only
    // https://remix.run/docs/en/main/guides/constraints#document-guard
    if (typeof document !== "undefined") {
      const analytics = initAnalytics();
      void analytics.page(undefined, {}, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <html lang="en">
      <head ref={headTagRef}>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {sentryMetaTags}
        <RemixReact.Meta />
        <RemixReact.Links />
        <meta name="msvalidate.01" content="38693D3F7765C467DD719998EFF502C3" />
      </head>
      <body>
        <Radix.Theme
          style={{ backgroundColor: "transparent" }}
          radius="small"
          accentColor="violet"
          grayColor="gray"
        >
          <AnalyticsContext.Provider value={analyticsContextProviderValue}>
            <MotionConfig reducedMotion="user">
              <SearchContext.Provider value={searchContextProviderValue}>
                <DialogContext.Provider value={dialogProviderValue}>
                  <Radix.Box
                    style={{ opacity: isLoading ? 0 : 1 }}
                    className={
                      isLoading
                        ? styles.pageContentLoading
                        : styles.pageContentIdle
                    }
                  >
                    {children}
                  </Radix.Box>
                </DialogContext.Provider>
              </SearchContext.Provider>
            </MotionConfig>
          </AnalyticsContext.Provider>
          <div
            ref={dialogContainer}
            className={styles.fullPageDialogContainer}
          ></div>
        </Radix.Theme>

        <RemixReact.Scripts />
        <RemixReact.ScrollRestoration />
        <Vivid.SnackbarContainer />
        <script
          dangerouslySetInnerHTML={{
            __html: `
        // Config
        window.CONFIG = ${JSON.stringify(data?.CONFIG ?? {})};
        `,
          }}
        />
      </body>
    </html>
  );
}

function App() {
  return <RemixReact.Outlet />;
}

export default SentryRemix.withSentry(App);

declare global {
  interface Window {
    CONFIG?: SerializeFrom<typeof loader>["CONFIG"];
    isSyntheticTest?: boolean;
  }
}
