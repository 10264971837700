import * as RemixReact from "@remix-run/react";
import React from "react";

export const useIsLoadingPage: () => { isLoading: boolean } = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigation = RemixReact.useNavigation();
  const location = RemixReact.useLocation();

  React.useEffect(() => {
    if (navigation.state === "idle") {
      setIsLoading(false);
    } else if (
      navigation.state === "loading" &&
      navigation.location.pathname !== location.pathname
    ) {
      setIsLoading(true);
    }
  }, [navigation, location]);

  return { isLoading: isLoading };
};
